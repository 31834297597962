<script>
import {
  HomeIcon,
} from 'vue-feather-icons';

/**
 * Page-error component
 */
export default {
  data() {
    return {};
  },
  components: {
    HomeIcon,
  },
};
</script>

<template>
<div>
    <div class="back-to-home rounded d-none d-sm-block">
        <router-link to="/" class="btn btn-icon btn-soft-primary">
            <home-icon class="icons"></home-icon>
        </router-link>
    </div>

    <!-- ERROR PAGE -->
    <section class="bg-home d-flex align-items-center">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-12 text-center">
                    <img src="/images/404.svg" style="max-height: 300px;" class="img-fluid" alt="">
                    <div class="text-capitalize text-dark mb-4 error-page">找不到頁面</div>
                    <p class="text-muted para-desc mx-auto">如果您想找的頁面不存在，請與我們聯繫。或者如果您是迷路了，那也可以回到首頁找找喔</p>
                </div>
                <!--end col-->
            </div>
            <!--end row-->

            <div class="row">
                <div class="col-md-12 text-center">
                    <router-link :to="{name: 'Home'}" class="btn btn-primary mt-4 ml-2">回首頁</router-link>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- ERROR PAGE -->
</div>
</template>
